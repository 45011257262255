import { observable, makeObservable } from 'mobx';

export default class FooterLink {

  id = "";
  title = "";
  type = "";
  url = "";

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      type: observable,
      url: observable
    });

    this.id = props.id
    this.title = props.title
    this.type = props.type
    this.url = props.url.startsWith("/details") ? `/guidelines${props.url}` : props.url;
  }
}